import "./index.css";

import { ConfigProvider } from "antd";
import i18n from "i18next";
import React from "react";
import ReactDOM from "react-dom/client";
import { initReactI18next } from "react-i18next";
import { RouterProvider } from "react-router-dom";

import { countryTranslation } from "./langs/country";
import { translation as translationEN } from "./langs/en";
import { translation as translationZH } from "./langs/zh";
import reportWebVitals from "./reportWebVitals";
import { router } from "./Router";
import LanguageDetector from "i18next-browser-languagedetector";

// const settings = JSON.parse(
//   localStorage.getItem("settings") || JSON.stringify({ lang: "zh_CN" })
// );
const opts = {
  resources: {
    en: {
      translation: translationEN,
    },
    zh: {
      translation: Object.assign(translationZH, countryTranslation),
    },
  },
  // lng: settings.lang, // if you're using a language detector, do not define the lng option
  // fallbackLng: settings.lang,
  fallbackLng: "zh_CN",
  lng: "zh_CN",

  // fallbackLng: navigator.language,
  // lng: navigator.language,
  interpolation: {
    escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
  },
};
i18n
  .use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init(opts);

async function init() {
  const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement
  );

  root.render(
    // <React.StrictMode>
    <>
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: "#9345f4",
            colorInfo: "#9345f4",
          },
          components: {
            Segmented: {
              itemSelectedColor: "#1677ff",
            },
          },
        }}
      >
        <RouterProvider router={router} />
      </ConfigProvider>
    </>
  );
}

init();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
