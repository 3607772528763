export const translation =
  // the translations
  // (tip move them in a JSON file and import them,
  // or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
  {
    Mallboost: "星熨达",
    Luckiads: "星熨达",
    Home: "首页",
    Services: "服务",
    SERVICES: "服务",
    Clients: "客户",
    About: "关于我们",
    ABOUT: "关于我们",
    Testimonials: "客户评价",
    Contact: "联系我们",
    CONTACT: "联系我们",
    About1:
      "星熨达一家专业的广告代理公司，致力于帮助您的品牌在全球增量最快最具影响力的社交媒体（如谷歌、FB、TIKTOK等）平台上取得成功。我们精通个品类投放，投放类型涉及全品类电、APP及H5为您提供全方位的广告解决方案。",
    About2:
      "团队投手在各自负责平台都至少有两年以上投放经验，从零到一扶持过多家企业，帮助企业打出品牌知名度、账户经理，运营人员均熟悉媒体及平台操作，能快速调配资源，帮助客户快速上手，合作更丝滑。",
    "Our Numbers": "我们的成绩",
    "Years of Experiece": "多年经验",
    "Qualified Experts": "资深投手",
    "Our Clients": "服务客户",
    "GMV/day": "GMV/日",
    "Taking Your Business to": "带领你的业绩",
    "the Next Level": "更上一层",

    "Senior Advertising Account Opening and Deployment Expert":
      "资深广告开户、投放专家",
    "provides an efficient advertising deployment and management platform":
      "提供高效的广告投放及管理平台",
    "To tailor advertising and brand marketing overall solutions for you, making it easy for you to expand globally.":
      "为您定制广告、品牌营销整体方案，让您轻松出海。",

    "SERVICES-1-1": "专业团队",
    "SERVICES-1-2": "丰富的广告经验，熟悉平台的各种功能和趋势",
    "SERVICES-1-3": "",
    "SERVICES-2-1": "全方位服务",
    "SERVICES-2-2": "从策划到投放，再到数据分析，我们提供一站式服务",
    "SERVICES-2-3": "",
    "SERVICES-3-1": "个性化方案",
    "SERVICES-3-2": "根据您的业务需求和目标，量身定制广告方案",
    "SERVICES-3-3": "",

    "Login By TikTok": "通过TikTok认证",

    "Let’s Work Together": "一起开始工作吧",
    // contactAddress: "深圳市南山区高新南九道45号西北工业大学三行科技大厦2307",
    contactAddress:
      "Flat/RM 02 7/F, SPA Centre, No.53-55 Lockhart Road, Wan Chai, Hong Kong",
    "Post Code": "邮编",
    Tel: "电话",
    Login: "登 陆",
    Logout: "退出系统",
    Console: "控制台",
    Register: "注册",
    Username: "用户名",
    Password: "密码",
    Confirm: "确认",
    Email: "邮箱地址",
    Accept: "同意",
    Submit: "提交",
    "User Services Agreement": "用户服务协议",
    "Please input your E-mail!": "请输入邮箱地址",
    "Please input password!": "请输入密码",
    "Please input confirm password!": "请再次输入密码",
    "Should accept agreement": "请确认用户协议",
    Welcome: "欢迎",
    "Please check the verify mail for Account Confirm": "请检查邮件以激活账号",
    "Wrong Username or Password": "错误的用户名或密码",
    Unconfirmed: "未验证",
    Locked: "已锁定",
    Forgot: "忘记",
    Mail: "邮件",
    Sent: "已发送",
    "Please check the verify mail for Passord Reset": "请检查邮件以重置密码",
    Failed: "失败",
    Success: "成功",
    Reset: "重置",
    Compare: "比对",
    Account: "账号",
    Audit: "审核",
    System: "系统",
    Settings: "设定",
    Management: "管理",
    Operator: "运营",
    BC: "商务中心",
    All: "全部",
    Index: "序号",
    Name: "名称",
    Nickname: "昵称",
    Action: "操作",
    Admin: "管理员",
    Type: "类型",
    Wait: "等待",
    View: "查看",
    Keyword: "关键字",
    Phone: "手机号",
    Unlock: "解锁",
    unlock: "解锁",
    Lock: "锁定",
    lock: "锁定",
    Is: "是否",
    Yes: "是",
    No: "否",
    Ok: "确定",
    Cancel: "取消",
    Profile: "账号信息",
    Avatar: "头像",
    Save: "保存",

    Monitoring: "监控",
    Review: "审核",
    Enterprise: "企业",
    Certification: "认证",
    Advertise: "广告",
    ADVERTISER: "广告账号",
    Traffic: "流量",
    Material: "素材",
    Financial: "财务",
    Request: "申请",
    Status: "状态",
    Balance: "余额",
    Notification: "通知",
    Trafficking: "投放",
    Statistics: "统计",
    Expense: "费用",
    Center: "中心",
    Reconciliation: "对账",
    "Click or drag file to this area to upload": "拖放文件以上传",
    Company: "公司",
    License: "执照",
    File: "文件",
    Start: "开始",
    START: "发起",
    Verify: "验证",
    Comfirm: "确认",
    Confirmed: "已确认",
    Comfirming: "确认中",
    Please: "请",
    Input: "输入",
    Upload: "上传",
    Scan: "扫描",
    Image: "图片",
    image: "图片",

    Info: "信息",

    "Verify Created": "待审核",
    "Verify Passed": "审核通过",
    "Verify Rejected": "审核不通过",
    "Verify Canceled": "取消审核",

    Pass: "通过",
    Reject: "驳回",
    Operation: "操作",
    Back: "返回",
    Description: "描述",
    Time: "时间",

    Charge: "充值",
    Finance: "财务",

    Used: "使用",
    Record: "记录",
    Num: "编号",
    Amount: "金额",

    Finish: "完成",
    Waitfor: "等待",

    Charging: "充值中",
    Create: "创建",
    Update: "更新",
    Modify: "更新",

    Publish: "发布",

    Keywords: "关键字",
    Media: "素材",
    Plays: "播放次数",
    Play: "播放",

    Clicks: "点击次数",
    Pays: "购买次数",

    Credit: "授信",
    Crediting: "授信中",
    User: "人",

    OpenAccount: "开户",
    CompanyLicenseNo: "统一社会信用代码",
    CompanyLicenseFile: "营业执照",
    Industry: "行业",
    Count: "数量",
    Timezone: "时区",
    CompanyRegisteCountry: "公司注册地",
    Spread: "推广",
    Link: "链接",

    Select: "选择",
    Group: "组",
    Campaign: "推广系列",
    Contract: "合约",

    Sync: "同步",
    Data: "数据",
    List: "列表",

    Need: "需要",
    Download: "下载",
    Target: "目标",
    Currency: "币种",
    Remark: "备注",
    Identity: "认证身份",
    Pixel: "像素",
    Video: "视频",
    video: "视频",

    New: "新增",
    Music: "音频",
    music: "音频",
    Delete: "删除",
    Advertiser: "广告账户",
    Close: "关闭",
    AdText: "广告文案",
    LandingPageUrl: "落地页 URL",

    Template: "模板",

    Shopping: "购物",
    VIDEO: "视频购物广告",
    LIVE: "直播购物广告",
    PRODUCT_SHOPPING_ADS: "商品购物广告",

    US: "美国",

    Promotion: "投放",
    Region: "区域",

    Gender: "性别",
    UNLIMITED: "不限",
    MALE: "男",
    FEMALE: "女",

    Age: "年龄",

    Interest: "兴趣",
    And: "与",
    Behavior: "行为",

    Budget: "预算",
    Schedule: "排期",

    BUDGET_MODE_TOTAL: "总预算",
    BUDGET_MODE_DYNAMIC_DAILY_BUDGET: "七日平均预算",
    BUDGET_MODE_DAY: "日预算",
    BUDGET_MODE_INFINITE: "不限预算",

    SCHEDULE_START_END: "设置开始时间和结束时间",
    SCHEDULE_FROM_NOW: "设置开始时间并持续投放",

    RF_REACH: "覆盖人数",
    PRODUCT_SALES: "商品销量",

    End: "结束",
    Choose: "选择",
    Date: "日期",
    Range: "范围",
    Dayparting: "投放安排",

    AuthCode: "帖子授权码",

    Cover: "封面",
    Validate: "有效",
    Shop: "店铺",
    Product: "产品",
    Category: "分类",

    Price: "价格",

    Selected: "已选择",
    Must: "需要",

    Text: "文案",

    "account's time zone": "广告账号时区",

    "Optimization Goal": "优化目标",
    Optimization_Goal_VALUE: "总收入",
    Optimization_Goal_CONVERT: "付费数/开始结账数",
    Optimization_Goal_CLICK: "点击数",

    Get: "获取",
    Title: "标题",

    objective_type: "推广目标",
    time_section: "分时段",
    "All Day": "全天",
    "Time Slice": "分时段",
    Rename: "重命名",
    Partner: "合作方",

    "Append Advertise": "追加广告",
    "On Ad Group": "至广告组",
    "On Campaign": "至推广系列",
    "New Advertise": "新广告",

    Remove: "移除",
    Search: "查询",

    Basic: "基础",

    Business: "商务",
    Rebate: "返点",
    Policy: "政策",

    "Investment Point": "代投点位",
    "Fixed Quarterly Fee": "固定季费",

    Fee: "费率",

    Affiliate: "挂靠",
    Agent: "代理",

    Edit: "编辑",

    Current: "当前",
    "First Party": "第一方",
    Picture: "图片",
  };
