export const countryTranslation = {
  CN: "中国",
  HK: "中国香港",
  MO: "中国澳门",
  TW: "中国台湾",
  SG: "新加坡",
  GB: "英国",
  US: "美国",
  RU: "俄罗斯",
  DE: "德国",
  CH: "瑞士",
  LU: "卢森堡",
  AT: "奥地利",
  LI: "列支敦士登",
  CA: "加拿大",
  FR: "法国",
  BE: "比利时",
  MC: "摩纳哥",
  IT: "意大利",
  JP: "日本",
  KR: "韩国",
  KZ: "哈萨克",
  FI: "芬兰",
  SE: "瑞典",
  HU: "匈牙利",
  IS: "冰岛",
  ID: "印尼",
  TR: "土耳其",
  UA: "乌克兰",
  PK: "巴基斯坦",
  IE: "爱尔兰",
  CZ: "捷克",
  CB: "加勒比海",
  AU: "澳洲",
  NZ: "新西兰",
  PH: "菲律宾",
  JM: "牙买加",
  BZ: "伯利兹",
  ZW: "津巴布韦",
  EE: "爱沙尼亚",
  FO: "法罗群岛",
  ES: "西班牙",
  AR: "阿根廷",
  MX: "墨西哥",
  BO: "玻利维亚",
  CL: "智利",
  PA: "巴拿马",
  VE: "委内瑞拉",
  CO: "哥伦比亚",
  PE: "秘鲁",
  CR: "哥斯达黎加",
  DO: "多米尼加共和国",
  EC: "厄瓜多尔",
  SV: "萨尔瓦多",
  GT: "危地马拉",
  HN: "洪都拉斯",
  NI: "尼加拉瓜",
  PY: "巴拉圭",
  PR: "波多黎各",
  UY: "乌拉圭",
  IN: "印度",
  NO: "挪威",
  PL: "波兰",
  BR: "巴西",
  PT: "葡萄牙",
  VN: "越南",
  TH: "泰国",
  ZA: "南非",
  BY: "白俄罗斯",
  IR: "伊朗王国",
  IL: "以色列",
  DK: "丹麦",
  MV: "马尔代夫",
  NL: "荷兰",
  GR: "希腊",
  SA: "沙特阿拉伯",
  EG: "埃及",
  IQ: "伊拉克",
  JO: "约旦",
  KW: "科威特",
  LB: "黎巴嫩",
  LY: "利比亚",
  QA: "卡塔尔",
  SY: "叙利亚共和国",
  BG: "保加利亚",
  AL: "阿尔巴尼亚",
  DZ: "阿尔及利亚",
  BH: "巴林",
  MA: "摩洛哥",
  OM: "阿曼",
  TN: "北非的共和国",
  AE: "阿拉伯联合酋长国",
  YE: "也门",
  AM: "亚美尼亚",
  KE: "肯尼亚",
  HR: "克罗埃西亚",
  GE: "格鲁吉亚州",
  LV: "拉脱维亚",
  LT: "立陶宛",
  MK: "马其顿",
  BN: "汶莱",
  MY: "马来西亚",
  MN: "蒙古",
  RO: "罗马尼亚",
  SK: "斯洛伐克",
  SI: "斯洛文尼亚",
  "uz-UZ-Cyrl": "乌兹别克",
  "UZ-Latn": "乌兹别克",
  CY: "塞浦路斯",
};
