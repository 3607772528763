import debounce from "debounce";
import {
  createContext,
  ReactElement,
  useContext,
  useEffect,
  useState,
} from "react";

export const defaultScreenSize = {
  width: 2520,
  height: 1080,
};
const ScreenControlContext = createContext<{
  rate: number;
  theme: string;
  setTheme?: any;
  size: {
    width: number;
    height: number;
  };
}>({
  rate: 1,
  theme: "black",
  size: defaultScreenSize,
});

export function ScreenControlProvider({
  children,
}: {
  children: ReactElement;
}) {
  const [theme, setTheme] = useState<string>("black");
  const [rate, setRate] = useState(1);
  const [size, setSize] = useState(defaultScreenSize);
  useEffect(() => {
    // let prevScreen: string;
    // function resetStyle() {
    //   const root = document.getElementById("root");
    //   root?.classList.remove("ueg");
    //   for (const k of Object.keys(rates)) {
    //     root?.classList.remove(k);
    //   }
    // }
    function setStyle() {
      // (document.querySelector("body") as any).style.transform = "scale(0.2)";
      const width = window.innerWidth;
      const height = window.innerHeight;
      setRate(height / defaultScreenSize.height);
      setSize({
        width,
        height,
      });
    }
    const style = debounce(setStyle, 600);
    window.addEventListener("resize", style);
    setStyle();

    return () => {
      window.removeEventListener("resize", style);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ScreenControlContext.Provider value={{ rate, theme, setTheme, size }}>
      {children}
    </ScreenControlContext.Provider>
  );
}

export function useScreenControl() {
  const { rate, size, theme, setTheme } = useContext(ScreenControlContext);
  return {
    rate,
    size,
    theme,
    setTheme,
  };
}
