import { Button, Result } from "antd";
import axios from "axios";
import {
  createBrowserRouter,
  Link,
  LoaderFunctionArgs,
} from "react-router-dom";

import { CheckAllow } from "./hooks/useAuth";
import { USER_TYPE } from "./layouts/ConsoleLayout";
import { Loader } from "./Loader";

const load = (element: string) => <Loader component={element} />;
const loadPage = (path: string) => ({
  path,
  element: load(`./pages${path}`),
});
export const router = createBrowserRouter(
  [
    {
      path: "/",
      element: load("./layouts/W3Layout"),
      children: [
        { index: true, element: load("./pages") },
        loadPage("/login"),
        loadPage("/home"),
        {
          path: "/auth",
          children: [
            {
              path: "confirm-email",
              element: load("./containers/auth/ConfirmEmail"),
            },
            {
              path: "forgot-password",
              element: load("./containers/auth/ForgotPassword"),
            },
            {
              path: "reset-password",
              element: load("./containers/auth/ResetPassword"),
            },
          ],
        },
      ],
    },
    {
      path: "ttredirect",
      element: load("./containers/www/TTRedirect"),
    },
    {
      path: "ttpredirect",
      element: load("./containers/www/TTPRedirect"),
    },
    {
      path: "input-email",
      element: load("./containers/www/InputEmail"),
    },
    {
      path: "/console",
      element: load("./layouts/ConsoleLayout"),
      children: [
        { index: true, element: load("./pages/console") },
        loadPage("/console/home"),
        loadPage("/console/profile"),
        {
          path: "admin",
          children: [
            {
              path: "settings",
              children: [
                {
                  path: "account-audit",
                  element: load("./containers/console/admin/settings/Accounts"),
                },
              ],
            },
          ],
        },

        {
          path: "bc",
          children: [],
        },

        {
          path: "settings",
          children: [],
        },

        {
          path: "balance",
          children: [
            {
              index: true,
              element: (
                <CheckAllow
                  types={[USER_TYPE.BC, USER_TYPE.OPERATOR, USER_TYPE.FINANCE]}
                >
                  {load("./containers/console/balance/BalanceIndex")}
                </CheckAllow>
              ),
            },
            {
              path: "charge/:companyId",
              loader: async (args: LoaderFunctionArgs) => {
                const {
                  params: { companyId },
                } = args;
                const { data } = await axios.get(`/api/company/${companyId}`);
                return data;
              },
              element: (
                <CheckAllow types={[USER_TYPE.BC]}>
                  {load("./containers/console/balance/Charge")}
                </CheckAllow>
              ),
            },
          ],
        },

        {
          path: "credit",
          children: [
            {
              index: true,
              element: (
                <CheckAllow types={[USER_TYPE.OPERATOR, USER_TYPE.FINANCE]}>
                  {load("./containers/console/credit/CreditIndex")}
                </CheckAllow>
              ),
            },
            {
              path: "charge/:companyId",
              loader: async (args: LoaderFunctionArgs) => {
                const {
                  params: { companyId },
                } = args;
                const { data } = await axios.get(`/api/company/${companyId}`);
                return data;
              },
              element: (
                <CheckAllow types={[USER_TYPE.OPERATOR]}>
                  {load("./containers/console/credit/Charge")}
                </CheckAllow>
              ),
            },
          ],
        },

        {
          path: "media",
          children: [
            {
              index: true,
              element: (
                <CheckAllow types={[USER_TYPE.OPERATOR, USER_TYPE.BC]}>
                  {load("./containers/console/media/MediaList")}
                </CheckAllow>
              ),
            },
            {
              path: ":id",
              loader: async (args: LoaderFunctionArgs) => {
                const {
                  params: { id },
                } = args;
                const { data } = await axios.get(`/api/media/${id}`);
                return { data };
              },
              element: (
                <CheckAllow types={[USER_TYPE.BC, USER_TYPE.OPERATOR]}>
                  {load("./containers/console/media/Media")}
                </CheckAllow>
              ),
            },
          ],
        },

        {
          path: "advertise",
          children: [
            {
              path: "publish",
              children: [
                {
                  index: true,
                  element: (
                    <CheckAllow types={[USER_TYPE.BC]}>
                      {load(
                        "./containers/console/advertise/publish/PublishAdvertise"
                      )}
                    </CheckAllow>
                  ),
                },
                {
                  path: "list",
                  element: (
                    <CheckAllow types={[USER_TYPE.BC, USER_TYPE.OPERATOR]}>
                      {load("./containers/console/advertise/publish/AdList")}
                    </CheckAllow>
                  ),
                },
                {
                  path: "report",
                  element: (
                    <CheckAllow types={[USER_TYPE.BC, USER_TYPE.OPERATOR]}>
                      {load("./containers/console/advertise/publish/Report")}
                    </CheckAllow>
                  ),
                },
              ],
            },
            {
              path: "sync",
              children: [
                {
                  path: "bc",
                  element: (
                    <CheckAllow types={[USER_TYPE.BC, USER_TYPE.OPERATOR]}>
                      {load("./containers/console/advertise/sync/BCList")}
                    </CheckAllow>
                  ),
                },
                {
                  path: "advertiser",
                  element: (
                    <CheckAllow types={[USER_TYPE.BC, USER_TYPE.OPERATOR]}>
                      {load("./containers/console/advertise/sync/Advertiser")}
                    </CheckAllow>
                  ),
                },
                {
                  path: "identity",
                  element: (
                    <CheckAllow types={[USER_TYPE.BC, USER_TYPE.OPERATOR]}>
                      {load("./containers/console/advertise/sync/IdentityList")}
                    </CheckAllow>
                  ),
                },
                {
                  path: "pixel",
                  element: (
                    <CheckAllow types={[USER_TYPE.BC, USER_TYPE.OPERATOR]}>
                      {load("./containers/console/advertise/sync/PixelList")}
                    </CheckAllow>
                  ),
                },

                {
                  path: "list",
                  element: (
                    <CheckAllow types={[USER_TYPE.BC]}>
                      {load("./containers/console/advertise/sync/AdList")}
                    </CheckAllow>
                  ),
                },

                {
                  path: "group",
                  element: (
                    <CheckAllow types={[USER_TYPE.BC, USER_TYPE.OPERATOR]}>
                      {load("./containers/console/advertise/sync/AdGroup")}
                    </CheckAllow>
                  ),
                },
                {
                  path: "campaign",
                  element: (
                    <CheckAllow types={[USER_TYPE.BC, USER_TYPE.OPERATOR]}>
                      {load("./containers/console/advertise/sync/Campaign")}
                    </CheckAllow>
                  ),
                },

                {
                  path: "video",
                  element: (
                    <CheckAllow types={[USER_TYPE.BC, USER_TYPE.OPERATOR]}>
                      {load("./containers/console/advertise/sync/VideoList")}
                    </CheckAllow>
                  ),
                },
                {
                  path: "music",
                  element: (
                    <CheckAllow types={[USER_TYPE.BC, USER_TYPE.OPERATOR]}>
                      {load("./containers/console/advertise/sync/MusicList")}
                    </CheckAllow>
                  ),
                },
                {
                  path: "image",
                  element: (
                    <CheckAllow types={[USER_TYPE.BC, USER_TYPE.OPERATOR]}>
                      {load("./containers/console/advertise/sync/ImageList")}
                    </CheckAllow>
                  ),
                },
                {
                  path: "partner",
                  element: (
                    <CheckAllow types={[USER_TYPE.BC, USER_TYPE.OPERATOR]}>
                      {load("./containers/console/advertise/sync/PartnerList")}
                    </CheckAllow>
                  ),
                },
                {
                  path: "shop",
                  element: (
                    <CheckAllow types={[USER_TYPE.BC, USER_TYPE.OPERATOR]}>
                      {load("./containers/console/advertise/sync/ShopList")}
                    </CheckAllow>
                  ),
                },
                {
                  path: "shop1",
                  element: (
                    <CheckAllow types={[USER_TYPE.BC, USER_TYPE.OPERATOR]}>
                      {load("./containers/console/advertise/sync/Shop1List")}
                    </CheckAllow>
                  ),
                },
                {
                  path: "product",
                  element: (
                    <CheckAllow types={[USER_TYPE.BC, USER_TYPE.OPERATOR]}>
                      {load("./containers/console/advertise/sync/ProductList")}
                    </CheckAllow>
                  ),
                },
              ],
            },
          ],
        },

        {
          path: "company",
          children: [
            {
              index: true,
              element: (
                <CheckAllow types={[USER_TYPE.BC, USER_TYPE.OPERATOR]}>
                  {load("./containers/console/verify/company/CompanyList")}
                </CheckAllow>
              ),
            },
          ],
        },

        {
          path: "verify",
          children: [
            {
              path: "company",
              children: [
                {
                  index: true,
                  element: (
                    <CheckAllow types={[USER_TYPE.BC, USER_TYPE.OPERATOR]}>
                      {load(
                        "./containers/console/verify/company/CompanyVerifyIndex"
                      )}
                    </CheckAllow>
                  ),
                },
                {
                  path: "start",
                  loader: async (args: LoaderFunctionArgs) => {
                    const { data: companys } = await axios.get(
                      `/api/company?pageSize=99`
                    );
                    return { companys };
                  },
                  element: (
                    <CheckAllow types={[USER_TYPE.BC]}>
                      {load(
                        "./containers/console/verify/company/StartCompanyVerify"
                      )}
                    </CheckAllow>
                  ),
                },
                {
                  path: ":id",
                  loader: async (args: LoaderFunctionArgs) => {
                    const {
                      params: { id },
                    } = args;
                    const { data } = await axios.get(`/api/verify/${id}`);
                    const { data: company } = await axios.get(
                      `/api/company/${data.entityId}`
                    );
                    return { verify: data, company };
                  },
                  element: (
                    <CheckAllow types={[USER_TYPE.BC, USER_TYPE.OPERATOR]}>
                      {load(
                        "./containers/console/verify/company/CompanyVerify"
                      )}
                    </CheckAllow>
                  ),
                },
              ],
            },
            {
              path: "account",
              children: [
                {
                  index: true,
                  element: (
                    <CheckAllow types={[USER_TYPE.ADMIN, USER_TYPE.OPERATOR]}>
                      {load("./containers/console/verify/account/Accounts")}
                    </CheckAllow>
                  ),
                },
              ],
            },
          ],
        },

        {
          path: "*",
          element: (
            <Result
              status="404"
              title={<div>404</div>}
              subTitle={<div>Sorry, the page you visited does not exist.</div>}
              extra={
                <Link to="/console">
                  <Button type="primary">Back Home</Button>
                </Link>
              }
            />
          ),
        },
      ],
    },
    {
      path: "*",
      element: (
        <Result
          status="404"
          title={<div>404</div>}
          subTitle={<div>Sorry, the page you visited does not exist.</div>}
          extra={
            <Link to="/">
              <Button type="primary">Back Home</Button>
            </Link>
          }
        />
      ),
    },
  ],
  {
    basename: "/",
  }
);
