import axios from "axios";

export function upload(options: {
  csrfToken: string;
  name: string;
  file: any;
  url: string;
  multiple: boolean;
}) {
  const { file, url, name, multiple = false, csrfToken } = options;

  const formData = new FormData();
  if (multiple) {
    for (const f of file) {
      formData.append(name, f);
      formData.append("originalname", encodeURIComponent(f.name));
    }
  } else {
    formData.append(name, file);
    formData.append("originalname", encodeURIComponent(file.name));
  }

  axios
    .post(url, formData, {
      headers: {
        "XSRF-TOKEN": csrfToken,
      },
    })
    .then(() => {
      console.log("File Upload success");
    })
    .catch((err) => console.log("File Upload Error", err));
}

export function parsePostBody(req: any) {
  return new Promise((resolve, reject) => {
    // var length = req.headers['content-length'] - 0;
    const arr: any[] = [];
    let chunks;
    req
      .on("error", (err: any) => {
        reject(err);
      })
      .on("data", (buff: any) => {
        arr.push(buff);
      })
      .on("end", () => {
        chunks = Buffer.concat(arr);
        resolve(chunks);
      });
  });
}

export function createChunks(
  file: any,
  // cSize = 1024 * 1024 * 30 /* cSize should be byte 1024*1 = 1KB */
  // cSize = 1024 * 255 /* mongo single chunk is 255K */
  cSize = 1024 * 255 * 10
) {
  if (!file) return [];
  let startPointer = 0;
  const endPointer = file.size;
  const chunks = [];
  while (startPointer < endPointer) {
    const newStartPointer = startPointer + cSize;
    chunks.push(file.slice(startPointer, newStartPointer));
    startPointer = newStartPointer;
  }
  return chunks;
}

export async function getChunkMd5(sparkMD5: any, chunk: any) {
  return new Promise((resolve) => {
    const fileReader = new FileReader();
    fileReader.readAsArrayBuffer(chunk);
    fileReader.onload = (e: any) => {
      // const md5: string = sparkMD5.ArrayBuffer.hash(e.target.result);
      const md5: string = sparkMD5.hash(e.target.result);
      resolve(md5);
    };
  });
}

export async function appendMd5(sparkMD5: any, chunk: any) {
  return new Promise((resolve) => {
    const fileReader = new FileReader();
    fileReader.readAsArrayBuffer(chunk);
    fileReader.onload = (e: any) => {
      const md5: string = sparkMD5.append(e.target.result);
      resolve(md5);
    };
  });
}

export async function getFileMd5(sparkMD5: any, file: any) {
  if (!file) return Promise.resolve("");
  return new Promise((resolve) => {
    const fileReader = new FileReader();
    fileReader.readAsArrayBuffer(file);
    fileReader.onload = (e: any) => {
      const md5: string = sparkMD5.ArrayBuffer.hash(e.target.result);
      resolve(md5);
    };
  });
}
