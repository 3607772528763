import moment from "moment-timezone";

export const passwordRegex =
  /^(?=.*[0-9])(?=.*[A-Z])(?=.*[a-z])(?=.*[!@#$%^&*?]).{7,20}\D$/;

export const emailRegex = /^\w+(-+.\w+)*@\w+(-.\w+)*.\w+(-.\w+)*$/;

export function guid() {
  // eslint-disable-next-line no-bitwise
  const firstPart: number = (Math.random() * 46656) | 0;
  // eslint-disable-next-line no-bitwise
  const secondPart: number = (Math.random() * 46656) | 0;
  const firstPartStr = `000${firstPart.toString(36)}`.slice(-3);
  const secondPartStr = `000${secondPart.toString(36)}`.slice(-3);
  return `${firstPartStr}${secondPartStr}`;
}

export function toObject(queryString: string) {
  if (!queryString) return {};
  let str = queryString;
  const idx = str.indexOf("?");
  if (idx !== -1) {
    str = str.substring(idx + 1);
  }
  str = str.endsWith("#") ? str.split("#")[0] : str;

  const obj: any = {};
  str.split("&").forEach((item: any) => {
    const [key, value] = item.split("=");
    if (obj[key]) {
      if (typeof obj[key] === "object") obj[key].push(value);
      else obj[key] = [obj[key], value];
    } else {
      obj[key] = value;
    }
  });
  return obj;
}
export function toQuery(params: any) {
  if (!params) return "";
  const parts: any[] = [];
  Object.entries(params).forEach(([key, val]) => {
    if (typeof val !== "undefined")
      parts.push(key + "=" + encodeURIComponent(val as string));
  });
  return parts.join("&");
}
export function toFullUrl(url: string, params: any) {
  Object.keys(toObject(url)).forEach((key) => delete params[key]);
  if (params)
    url = url + (url.indexOf("?") === -1 ? "?" : "&") + toQuery(params);
  if (url.endsWith("&")) url = url.substring(0, url.length - 1);
  if (url.indexOf("://") === -1) {
    if (url[0] !== "/") url = "/" + url;
  }
  return url;
}

const { lang } = JSON.parse(
  localStorage.getItem("settings") || JSON.stringify({ lang: "en" })
);
export let tt: any = function (t: any, ...keys: string[]) {
  return keys.map((key) => t(key)).join(lang === "en" ? "" : "");
  // return keys.map((key) => t(key)).join(lang === "en" ? " " : "");
};

export const getBase64 = (file: any): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });

export function getTimezones() {
  const timezones: any[] = [];

  const countries = moment.tz.countries();

  for (const country of countries) {
    const zones = moment.tz.zonesForCountry(country, true);

    for (const zone of zones) {
      if (timezones.findIndex((z) => z.name === zone.name) !== -1) continue;
      timezones.push({
        country,
        name: zone.name, // moment().tz("Asia/Shanghai").utcOffset();
        offset: zone.offset,
        title: `${country} (UTC${zone.offset < 0 ? "+" : "-"}${
          Math.abs(zone.offset) / 60 >= 10 ? "" : "0"
        }${Math.floor(Math.abs(zone.offset) / 60)}:00) ${zone.name}`,
      });
    }
  }

  return timezones;
}

export * from "./upload";
