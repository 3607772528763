import { Button, Result } from "antd";
import axios from "axios";
import { useEffect, useState } from "react";
import { Link, useNavigate, useOutletContext } from "react-router-dom";

import { USER_TYPE } from "../layouts/ConsoleLayout";
import Axios from "../utils/axios";

export function useProfile() {
  const [profile, setProfile] = useState<any>({});
  const nav = useNavigate();
  useEffect(() => {
    const getProfile: any = (event: CustomEvent) => {
      const { action } = event?.detail || {};
      Axios.get("/api/auth/profile").then((res) => {
        if (res.data) {
          setProfile(res.data);
          switch (action) {
            case "redirect":
              const { url } = event.detail;
              nav(url);
              break;

            default:
              break;
          }
        } else
          window.location.href = `/?redirect=${encodeURIComponent(
            `${window.location.href}`
          )}`;
      });
    };
    getProfile();
    window.addEventListener("profile.changed", getProfile);
    return () => {
      window.removeEventListener("profile.changed", getProfile);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return profile;
}

export function useGetProfile() {
  const [profile, setProfile] = useState<any>({});
  useEffect(() => {
    function getProfile() {
      axios
        .get("/api/auth/profile")
        .then((res) => setProfile(res.data || {}))
        .catch(() => {});
    }
    getProfile();
    window.addEventListener("profile.changed", getProfile);
    return () => {
      window.removeEventListener("profile.changed", getProfile);
    };
  }, []);

  return profile;
}

export function CheckAllow({
  types,
  children,
}: {
  types: USER_TYPE[];
  children: any;
}) {
  const { profile }: any = useOutletContext();
  if (types.includes(profile.type)) return children;
  return (
    <Result
      status="404"
      title={<div>405</div>}
      subTitle={<div>Sorry, the page you visited does not allowed.</div>}
      extra={
        <Link to="/console">
          <Button type="primary">Back Home</Button>
        </Link>
      }
    />
  );
}
