export const translation =
  // the translations
  // (tip move them in a JSON file and import them,
  // or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
  {
    Login: "Login",
    Logout: "Exit System",
    Console: "Console",
    About1:
      "is a professional advertising agency dedicated to helping your brand succeed on the world's fastest growing and most influential social media platforms (such as Google, FB, TIKTOK, etc.). We are proficient in advertising in each category, and the advertising types include all categories of electronics, APP and H5 to provide you with a full range of advertising solutions.",
    About2:
      "Team pitchers all have at least two years of experience in advertising on their respective platforms. They have supported many companies from scratch and helped them build brand awareness. Account managers and operation staff are all familiar with media and platform operations and can quickly allocate resources to help customers. Get started quickly and collaborate more smoothly.",

    "SERVICES-1-1": "Professional Team",
    "SERVICES-1-2": "Rich advertising experience",
    "SERVICES-1-3": "familiar with various functions",

    "SERVICES-2-1": "Full Service",
    "SERVICES-2-2": "From planning to delivery to data analysis",
    "SERVICES-2-3": "we provide one-stop service",

    "SERVICES-3-1": "Personalized plan",
    "SERVICES-3-2": "Tailor advertising solutions based",
    "SERVICES-3-3": "on your business needs and goals",

    contactAddress:
      "Room 2307, Sanhang Technology Building, Northwestern Polytechnical University, No. 45, Gaoxin Nanjiu Road, Nanshan District, Shenzhen",

    START: "Start",

    Optimization_Goal_VALUE: "VALUE",
    Optimization_Goal_CONVERT: "CONVERT",
    Optimization_Goal_CLICK: "CLICK",
  };
