import { Suspense, lazy } from "react";

export function Loader(props: { component: string }) {
  const LazyComponent = lazy(() => import(`${props.component}`));
  return (
    <Suspense fallback={<div className="py-2 px-4 text-white">Loading...</div>}>
      <LazyComponent />
    </Suspense>
  );
}
